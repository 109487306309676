.container {
  /* Base */
  --_prose-font-size: var(--prose-font-size, var(--fluid-font-size-base));
  --_prose-line-height: var(--prose-line-height, var(--line-height-base));
  --_prose-color-body: var(--prose-color-body, var(--color-text));

  /* Links */
  --_prose-a-font-weight: inherit;
  --_prose-color-links: var(--color-text);
  --_prose-a-text-decoration: var(--link-text-decoration, initial);
  --_prose-a-text-decoration-style: var(--link-text-decoration-style, initial);
  --_prose-a-text-decoration-color: var(--link-text-decoration-color, initial);
  --_prose-a-text-decoration-line: var(--link-text-decoration-line, initial);
  --_prose-a-text-underline-offset: var(--link-text-underline-offset, initial);

  /* Strong */
  --prose-strong-font-weight: 600;
  --prose-color-bold: inherit;

  /* Lead */
  --prose-lead-font-size: 1.25em; /* em(20, 16) */
  --prose-lead-line-height: 1.6;
  --prose-lead-margin: 1.2em;
  --prose-color-lead: var(--color-text);

  /* Paragraph */
  --prose-p-margin: 1em;

  /* Blockquote */
  /* H1 */
  --prose-h1-font-size: 2.25em;
  --prose-h1-line-height: 1.1111111111;
  --prose-h1-margin-top: 0;
  --prose-h1-margin-bottom: 0.8888888889em;
  --prose-h1-font-weight: 400;
  --prose-h1-bold-font-weight: 700;

  /* H2 */
  --prose-h2-font-size: 1.5em; /* em(24, 16), */
  --prose-h2-margin-top: 2em; /* em(48, 24), */
  --prose-h2-margin-bottom: 1em; /* em(24, 24), */
  --prose-h2-line-height: 1.3333333333; /* round(32 / 24), */
  --prose-h2-font-weight: 400;
  --prose-h2-bold-font-weight: 700;

  /* H3 */
  --prose-h3-font-size: 1.25em; /* em(20, 16), */
  --prose-h3-margin-top: 1.6em; /* em(32, 20), */
  --prose-h3-margin-bottom: 0.6em; /* em(12, 20), */
  --prose-h3-line-height: 1.6; /* round(32 / 20), */
  --prose-h3-font-weight: 400;
  --prose-h3-bold-font-weight: 700;

  /* H4 */
  --prose-h4-font-size: 1em; /* em(16, 16), */
  --prose-h4-margin-top: 1.5em; /* em(24, 16), */
  --prose-h4-margin-bottom: 0.5em; /* em(8, 16), */
  --prose-h4-line-height: 1.5; /* round(24 / 16), */
  --prose-h4-font-weight: 400;
  --prose-h4-bold-font-weight: 700;

  /* Img */
  --prose-img-margin-top: 2em; /* em(32, 16), */
  --prose-img-margin-bottom: 2em; /* em(32, 16), */

  /* Video */
  --prose-video-margin-top: 2em; /* em(32, 16), */
  --prose-video-margin-bottom: 2em; /* em(32, 16), */

  /* Figure */
  --prose-figure-margin-top: 2em; /* em(32, 16), */
  --prose-figure-margin-bottom: 2em; /* em(32, 16), */

  /* Fig Caption */
  --prose-figcaption-font-size: 0.875em; /* em(14, 16), */
  --prose-figcaption-line-height: 1.4285714286; /* round(20 / 14), */
  --prose-figcaption-margin-top: 0.8571428571em; /* em(12, 14), */

  /* Code */
  --prose-code-font-size: 0.875em; /* em(14, 16), */
  --prose-color-code: var(--color-text);

  /* Pre */
  --prose-pre-font-size: 0.875em; /* em(14, 16), */
  --prose-pre-line-height: 1.5; /* round(24 / 14), */
  --prose-pre-margin-top: 1.7142857143em; /* em(24, 14), */
  --prose-pre-margin-bottom: 1.7142857143; /* em(24, 14), */
  --prose-pre-border-radius: 0.375rem; /* rem(6), */
  --prose-pre-padding-top: 0.8571428571em; /* em(12, 14), */
  --prose-pre-padding-right: 1.1428571429em; /* em(16, 14), */
  --prose-pre-padding-bottom: 0.8571428571em; /* em(12, 14), */
  --prose-pre-padding-left: 1.1428571429em; /* em(16, 14), */
  --prose-color-pre-code: var(--color-white);

  /* Lists */
  --prose-ul-margin-top: 1.25em; /* em(20, 16), */
  --prose-ul-margin-bottom: 1.25em; /* em(20, 16), */
  --prose-ul-padding-left: 1.625em; /* em(26, 16), */

  --prose-ol-margin-top: 1.25em; /* em(20, 16), */
  --prose-ol-margin-bottom: 1.25em; /* em(20, 16), */
  --prose-ol-padding-left: 1.625em; /* em(26, 16), */

  --prose-li-margin-top: 0.5em; /* em(8, 16), */
  --prose-li-margin-bottom: 0.5em; /* em(8, 16), */

  /* HR */
  --prose-hr-margin-top: 3em; /* em(48, 16), */
  --prose-hr-margin-bottom: 3em; /* em(48, 16), */
  --prose-color-hr: var(--color-text);

  /* Tables */
  --prose-table-font-size: 0.875em; /* em(14, 16), */
  --prose-table-line-height: 1.7142857143; /* round(24 / 14), */
  --prose-table-margin-top: 2em; /* em(32, 16) */
  --prose-table-margin-bottom: 2em; /* em(32, 16) */

  --prose-td-padding: 0.5714285714em; /* em(8, 14), */

  /* Colors */
  --prose-color-counters: var(--color-text);
  --prose-color-bullets: var(--color-text);

  --prose-color-quotes: var(--color-text);
  --prose-color-quote-borders: var(--color-text);
  --prose-color-headings: var(--color-text);
  --prose-color-captions: var(--color-text-muted);

  --prose-color-pre-bg: var(--color-text);
  --prose-color-th-borders: var(--color-text);
  --prose-color-td-borders: var(--color-text);
}

.container {
  color: var(--_prose-color-body);
  font-size: var(--_prose-font-size);
  line-height: var(--_prose-line-height);
  font-family: var(--font-family-primary);
}

.container p {
  margin-top: var(--prose-p-margin); /* em(20, 16) */
  margin-bottom: var(--prose-p-margin); /* em(20, 16) */
}

.container a {
  color: var(--_prose-color-links);
  font-weight: var(--_prose-a-font-weight);
  text-decoration-line: var(--_prose-a-text-decoration-line);
  text-decoration-style: var(--_prose-a-text-decoration-style);
  text-decoration-color: var(--_prose-a-text-decoration-color);
  text-underline-offset: var(--_prose-a-text-underline-offset);
}

/* Bespoke for this project */
.container a:is(:hover, :active, :focus-within) {
  text-decoration-color: var(--_prose-color-links);
}

.container strong {
  color: var(--prose-color-bold);
  font-weight: var(--prose-strong-font-weight);
}

.container a strong {
  color: inherit;
}

.container [class~="lead"] {
  margin-top: var(--prose-lead-margin);
  margin-bottom: var(--prose-lead-margin);
  color: var(--prose-color-lead);
  font-size: var(--prose-lead-font-size);
  line-height: var(--prose-lead-line-height);
}

.container blockquote {
  margin-top: 1.6em; /* em(32, 20) */
  margin-bottom: 1.6em; /* em(32, 20) */
  padding-left: 1em; /* em(20, 20) */
}

.container h1 {
  margin-top: var(--prose-h1-margin-top);
  margin-bottom: var(--prose-h1-margin-bottom);
  font-size: var(--prose-h1-font-size);
  line-height: var(--prose-h1-line-height);
}

.container h2 {
  margin-top: var(--prose-h2-margin-top);
  margin-bottom: var(--prose-h2-margin-bottom);
  font-size: var(--prose-h2-font-size);
  line-height: var(--prose-h2-line-height);
}

.container h3 {
  margin-top: var(--prose-h3-margin-top);
  margin-bottom: var(--prose-h3-margin-bottom);
  font-size: var(--prose-h3-font-size);
  line-height: var(--prose-h3-line-height);
}

.container h4 {
  margin-top: var(--prose-h4-margin-top);
  margin-bottom: var(--prose-h4-margin-bottom);
  font-size: var(--prose-h4-font-size);
  line-height: var(--prose-h4-line-height);
}

.container :is(h1, h2, h3, h4, h5, h6) {
  overflow-wrap: break-word; /* opinionated default */
  word-wrap: break-word; /* opinionated default */
  hyphens: auto; /* opinionated default */
}

.container img {
  margin-top: var(--prose-img-margin-top);
  margin-bottom: var(--prose-img-margin-bottom);
  border-radius: var(--border-radius-responsive); /* opinionated default */
  max-width: 100%; /* opinionated default */
  height: auto; /* opinionated default */
}

.container video {
  margin-top: var(--prose-video-margin-top);
  margin-bottom: var(--prose-video-margin-bottom);
}

.container figure {
  margin-top: var(--prose-figure-margin-top);
  margin-right: 0; /* override */
  margin-bottom: var(--prose-figure-margin-bottom);
  margin-left: 0; /* override */
}

.container figure > * {
  margin-top: 0;
  margin-bottom: 0;
}

.container figure > img {
  display: block; /* opinionated default */
  width: 100%; /* opinionated default */
  height: auto; /* opinionated default */
}

.container figcaption {
  margin-top: var(--prose-figcaption-margin-top);
  font-size: var(--prose-figcaption-font-size);
  line-height: var(--prose-figcaption-line-height);
}

.container code {
  font-size: var(--prose-code-font-size);
}

.container h2 code {
  font-size: 0.875em; /* em(21, 24), */
}

.container h3 code {
  font-size: 0.9em; /* em(18, 20), */
}

.container pre {
  margin-top: var(--prose-pre-margin-top);
  margin-bottom: var(--prose-pre-margin-bottom);
  border-radius: var(--prose-pre-border-radius);
  padding-top: var(--prose-pre-padding-top);
  padding-right: var(--prose-pre-padding-right);
  padding-bottom: var(--prose-pre-padding-bottom);
  padding-left: var(--prose-pre-padding-left);
  font-size: var(--prose-pre-font-size);
  line-height: var(--prose-pre-line-height);
}

.container ol {
  margin-top: var(--prose-ol-margin-top);
  margin-bottom: var(--prose-ol-margin-bottom);
  padding-left: var(--prose-ol-padding-left);
}

.container ul {
  margin-top: var(--prose-ul-margin-top);
  margin-bottom: var(--prose-ul-margin-bottom);
  padding-left: var(--prose-ul-padding-left);
}

.container li {
  margin-top: var(--prose-li-margin-top);
  margin-bottom: var(--prose-li-margin-bottom);
}

.container ol > li {
  padding-left: 0.375em; /* em(6, 16), */
}

.container ul > li {
  padding-left: 0.375em; /* em(6, 16), */
}

.container > ul > li p {
  margin-top: 0.75em; /* em(12, 16), */
  margin-bottom: 0.75em; /* em(12, 16), */
}

.container > ul > li > *:first-child {
  margin-top: 1.25em; /* em(20, 16), */
}

.container > ul > li > *:last-child {
  margin-bottom: 1.25em; /* em(20, 16), */
}

.container > ol > li > *:first-child {
  margin-top: 1.25em; /* em(20, 16), */
}

.container > ol > li > *:last-child {
  margin-bottom: 1.25em; /* em(20, 16), */
}

.container ul ul,
.container ul ol,
.container ol ul,
.container ol ol {
  margin-top: 0.75em; /* em(12, 16), */
  margin-bottom: 0.75em; /* em(12, 16), */
}

.container hr {
  margin-top: var(--prose-hr-margin-top);
  margin-bottom: var(--prose-hr-margin-bottom);
}

.container hr + * {
  margin-top: 0;
}

.container h2 + * {
  margin-top: 0;
}

.container h3 + * {
  margin-top: 0;
}

.container h4 + * {
  margin-top: 0;
}

.container table {
  font-size: var(--prose-table-font-size);
  line-height: var(--prose-table-line-height);
}

.container thead th {
  padding-right: var(--prose-td-padding);
  padding-bottom: var(--prose-td-padding);
  padding-left: var(--prose-td-padding);
}

.container thead th:first-child {
  padding-left: 0;
}

.container thead th:last-child {
  padding-right: 0;
}

.container tbody td,
.container tfoot td {
  padding-top: var(--prose-td-padding);
  padding-right: var(--prose-td-padding);
  padding-bottom: var(--prose-td-padding);
  padding-left: var(--prose-td-padding);
}

.container tbody td:first-child,
.container tfoot td:first-child {
  padding-left: 0;
}

.container tbody td:last-child,
.container tfoot td:last-child {
  padding-right: 0;
}

.container > :first-child {
  margin-top: 0;
}

.container > :last-child {
  margin-bottom: 0;
}

.container blockquote strong {
  color: inherit;
}

.container thead th strong {
  color: inherit;
}

.container ol {
  list-style-type: decimal;
}

.container ol[type="A"] {
  list-style-type: upper-alpha;
}

.container ol[type="a"] {
  list-style-type: lower-alpha;
}

.container ol[type="I"] {
  list-style-type: upper-roman;
}

.container ol[type="i"] {
  list-style-type: lower-roman;
}

.container ol[type="1"] {
  list-style-type: decimal;
}

.container ul {
  list-style-type: disc;
}

.container ol > li::marker {
  color: var(--prose-color-counters);
  font-weight: 400;
}

.container ul > li::marker {
  color: var(--prose-color-bullets);
}

.container hr {
  border-top-width: 1px;
  border-color: var(--prose-color-hr);
}

.container blockquote {
  border-left-width: 0.25rem;
  border-left-color: var(--prose-color-quote-borders);
  quotes: "\201C" "\201D" "\2018" "\2019";
  color: var(--prose-color-quotes);
  font-style: italic;
  font-weight: 500;
}

.container blockquote p:first-of-type::before {
  content: open-quote;
}

.container blockquote p:last-of-type::after {
  content: close-quote;
}

.container h1 {
  color: var(--prose-color-headings);
  font-weight: var(--prose-h1-font-weight);
}

.container h1 strong {
  color: inherit;
  font-weight: var(--prose-h1-bold-font-weight);
}

.container h2 {
  color: var(--prose-color-headings);
  font-weight: var(--prose-h2-font-weight);
}

.container h2 strong {
  color: inherit;
  font-weight: var(--prose-h2-bold-font-weight);
}

.container h3 {
  color: var(--prose-color-headings);
  font-weight: var(--prose-h3-font-weight);
}

.container h3 strong {
  color: inherit;
  font-weight: var(--prose-h3-bold-font-weight);
}

.container h4 {
  color: var(--prose-color-headings);
  font-weight: var(--prose-h4-font-weight);
}

.container h4 strong {
  color: inherit;
  font-weight: var(--prose-h4-bold-font-weight);
}

.container figcaption {
  color: var(--prose-color-captions);
}

.container code {
  color: var(--prose-color-code);
  font-weight: 600;
}

.container code::before {
  content: "`";
}

.container code::after {
  content: "`";
}

.container a code {
  color: inherit;
}

.container :is(h1, h2, h3, h4, h5, h6, blockquote, thead th) code {
  color: inherit;
}

.container pre {
  background-color: var(--prose-color-pre-bg);
  overflow-x: auto;
  color: var(--prose-color-pre-code);
  font-weight: 400;
}

.container pre code {
  border-width: 0;
  border-radius: 0;
  background-color: transparent;
  padding: 0;
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
}

.container pre :is(code::before, code::after) {
  content: none;
}

.container table {
  margin-top: var(--prose-table-margin-top);
  margin-bottom: var(--prose-table-margin-bottom);
  width: 100%;
  table-layout: auto;
  text-align: left;
}

.container thead {
  border-bottom-width: 1px;
  border-bottom-color: var(--prose-color-th-borders);
}

.container thead th {
  vertical-align: bottom;
  color: var(--prose-color-headings);
  font-weight: 600;
}

.container tbody tr {
  border-width: 0;
  border-bottom-width: 1px;
  border-style: solid;
  border-bottom-color: var(--prose-color-td-borders);
}

.container tbody tr:last-child {
  border-bottom-width: 0;
}

.container tbody td {
  vertical-align: baseline;
}

.container tfoot {
  border-top-width: 1px;
  border-top-color: var(--prose-color-th-borders);
}

.container tfoot td {
  vertical-align: top;
}
