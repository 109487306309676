.header {
  --_logo-width: 225px;
  --_header-logo-height: var(--header-logo-height);
  --_header-padding-block: var(--header-padding-block);
  --_gap: 56px;

  /**
   * 1. position and z-index so that the open-nav sits over the content below
   */
  display: grid;
  position: relative; /* 1 */
  top: 0; /* for peek animation */
  left: 0; /* for peek animation */
  grid-template-columns: 1fr auto;
  column-gap: 1rem;
  row-gap: var(--grid-column-gap);
  align-items: center;
  z-index: var(--z-index-header, 999); /* 1 */
  transition: transform 300ms linear;
  background-color: white;
  isolation: isolate;
  padding-inline: var(--container-padding-inline);
  padding-block: var(--container-padding-inline);
  width: 100%; /* for peek animation */
  font: var(--font-primary);
  font-size: var(--font-size-20px);

  @media (width > 576px) {
    grid-template-columns: var(--_logo-width) minmax(0, 1fr);
    column-gap: var(--_gap);
    align-items: start;
    padding-block: var(--_header-padding-block);
  }
}

/**
 * "claim" is only shown on homepage, there are no breadcrumbs on homepage,
 * so we go single col on on mobile so the claim can go full width.
 */
.header[data-claim="true"] {
  grid-template-columns: 1fr;

  @media (width > 576px) {
    grid-template-columns: 1fr auto;
    column-gap: var(--_gap);
  }
}

.header[data-variant="visible"] {
  @media (width > 576px) {
    position: fixed;
    top: 0;
    transform: translateY(0);
  }
}

.header[data-variant="hidden"] {
  @media (width > 576px) {
    position: fixed;
    top: 0;
    transform: translateY(-100%);
  }
}

.logoContainer {
  display: flex;
}

.logoLink {
  display: inline-block;
  line-height: 1;
}

.logo {
  display: block;
  transition: height 300ms linear;
  max-width: var(--_logo-width);
  height: calc(var(--font-size-20px) * var(--line-height-base));

  @media (width > 576px) and (prefers-reduced-motion) {
    height: calc(var(--font-size-20px) * var(--line-height-base));
  }

  @media (width > 576px) {
    display: block;
    height: var(--_header-logo-height);
  }
}

.logo[data-size="small"] {
  @media (width > 576px) {
    height: calc(var(--font-size-20px) * var(--line-height-base));
  }
}

/* Mobile we don't show the logo, just text */
.logo-text {
  display: none;
  white-space: nowrap;

  @media (width > 576px) {
    display: none;
  }
}

.inner {
  display: grid;
  column-gap: var(--_gap);
  justify-self: end;
  width: 100%;
  max-width: 780px;

  @media (width > 576px) {
    grid-template-columns: minmax(0, 1fr) auto;
    column-gap: 1rem;
  }

  @media (width > 768px) {
    column-gap: var(--_gap);
  }
}

.page-title ol {
  display: flex;
  column-gap: var(--_gap);
  justify-self: flex-start;
  order: 1;
  font-size: var(--font-size-16px);
  line-height: 1;
  text-align: right;

  @media (width > 576px) {
    font-size: var(--font-size-20px);
    line-height: var(--line-height-base);
    text-align: left;
  }
}

/* on mobile hide everthing apart from last item */
.page-title li {
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  position: absolute;
  margin: -1px;
  border-width: 0;
  padding: 0;
  width: 1px;
  height: 1px;
  white-space: nowrap;

  @media (width > 992px) {
    overflow: initial;
    clip: initial;
    position: initial;
    margin: initial;
    width: initial;
    height: initial;
    white-space: initial;
  }

  /* Homepage */
  &:first-child {
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    position: absolute;
    margin: -1px;
    border-width: 0;
    padding: 0;
    width: 1px;
    height: 1px;
    white-space: nowrap;
  }

  &:last-child {
    overflow: initial;
    clip: initial;
    position: initial;
    margin: initial;
    width: initial;
    height: initial;
    white-space: initial;
  }
}

.navigation {
  order: 2;
}
