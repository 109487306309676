/* Make sure to the mobile breakpoint in the JS file  */

.container {
  --_margin: var(--container-padding-inline);

  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  row-gap: 2rem;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  padding-inline: var(--_margin);
  padding-block: var(--_margin);

  @media (width > 576px) {
    position: initial;
    right: initial;
    bottom: initial;
    justify-content: flex-start;
    padding-inline: initial;
    padding-block: initial;
  }

  &[data-open="true"] {
    top: 0;
    width: 100%;
    min-height: 100dvh;

    @media (width > 576px) {
      top: initial;
      width: initial;
      min-height: initial;
    }
  }
}

.nav {
  display: flex;
  position: relative; /* Sets the position of `.menu` */
  justify-content: flex-end;

  @media (width > 576px) {
    display: block;
    position: relative; /* Sets the position of `.menu` */
    column-gap: 56px;
    justify-content: flex-end;
  }

  @media (width > 768px) {
    display: flex;
  }
}

.button {
  --border-color: var(--color-grey);
  --border-width: 1px;
  --padding: 6px 14px;

  z-index: 2;
  border: var(--border-dotted);
  border-width: var(--border-width);
  border-color: var(--border-color);
  border-radius: 50px;
  background: rgba(255, 255, 255, 0.9);
  padding: var(--padding);
  color: var(--color-black);
  font: var(--font-primary);
  font-size: var(--font-size-20px);
  text-align: right;

  @media (width > 576px) {
    order: 2;
    border-width: 0;
    border-radius: 0;
    background-color: transparent;
    padding: initial;
  }
}

.button:is(:hover, :active, :focus) {
  --border-color: var(--color-black);

  @media (width > 576px) {
    text-decoration-line: var(--link-text-decoration-line);
    text-decoration-style: var(--link-text-decoration-style);
    text-decoration-color: var(--link-text-decoration-color);
    text-underline-offset: var(--link-text-underline-offset);
  }
}

.menu {
  /* Hidden by default, shown when menu is toggled */
  display: flex; /* override [hidden] */
  flex-direction: column;
  align-items: flex-end;
  padding-inline: var(--_margin);
  padding-block: 10px;
  width: auto;

  @media (width > 576px) {
    display: none;
    position: absolute;
    top: 100%;
    right: calc(-1 * var(--_margin));
    background-color: rgba(255, 255, 255, 0.9);
  }
}

/* In case we need to initially set display: none; */
[data-open="true"] .menu,
[data-closing="true"] .menu {
  display: flex;
}

.items {
  display: flex;
  row-gap: 12px;
  flex-direction: column;
  padding: 0;
  list-style: none;
  text-align: right;

  /* For landscape phones break nav onto 1 line */
  @media (height < 400px) {
    display: flex;
    column-gap: 1rem;
    row-gap: 1rem;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
  }
}

@media (width > 576px) {
  .item:first-child {
    display: none;
  }
}

.link {
  color: var(--color-text);
  font: var(--font-primary);
  font-size: var(--font-size-18px);

  &[aria-current="page"] {
    color: var(--color-black);
  }

  &:is(:hover, :active, :focus-visibile) {
    text-decoration-line: var(--link-text-decoration-line);
    text-decoration-style: var(--link-text-decoration-style);
    text-decoration-color: var(--link-text-decoration-color);
    text-underline-offset: var(--link-text-underline-offset);
  }

  @media (width > 576px) {
    color: var(--color-text-muted);
    font-size: var(--font-size-20px);
  }
}

/* This is a copy of the "language switch". It came as a last minute fix so we've duplicated it */
.menu-language-switch {
  display: none;

  @media (width > 576px) {
    display: flex; /* override [hidden] */
    justify-content: flex-end;
    gap: 10px;
    margin-top: var(--_margin);
    margin-inline: calc(var(--_margin) * -1);
    border-top: var(--border-dotted);
    padding-top: 10px;
    padding-inline: var(--_margin);
    width: calc(100% + var(--_margin) * 2);
    font-size: var(--font-size-18px);
  }

  @media (width > 768px) {
    display: none;
  }
}

/* languages */
.language-switch {
  display: flex; /* override [hidden] */
  position: absolute;
  top: var(--_margin);
  justify-self: end;
  gap: 10px;
  margin-right: var(--_margin);
  margin-bottom: auto;
  font-size: var(--font-size-20px);

  @media (width > 576px) {
    display: none; /* We switch to a copy of the language switch at this BP */
  }

  @media (width > 768px) {
    display: flex;
    position: initial;
    order: 1;
    margin-right: initial;
    margin-bottom: initial;
  }
}

/* In case we need to initially set display: none; */
/*
[data-open="true"] .languageSwitch,
[data-closing="true"] .languageSwitch {
  display: flex;
}
*/

.language-button {
  color: var(--color-text-muted);
  text-transform: uppercase;
}

.language-button:is(:hover, :active, :focus) {
  text-decoration-line: var(--link-text-decoration-line);
  text-decoration-style: var(--link-text-decoration-style);
  text-decoration-color: var(--link-text-decoration-color);
  text-underline-offset: var(--link-text-underline-offset);
}

.language-button--active {
  color: var(--color-text);
}

.clip-wrapper {
  display: flex;
  position: fixed;
  right: 0;
  bottom: 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
  z-index: 1;
  clip-path: circle(0px at calc(100% - 58px) calc(100% - 38px));
  transition: all 0.3s ease-in-out;
  background-color: rgba(255, 255, 255, 0.9);
  width: 100%;
  height: 100%;

  @media (width < 576px) and (prefers-reduced-motion) {
    display: none;
    position: initial;
    right: initial;
    bottom: initial;
    clip-path: initial;
    background-color: transparent;
    width: initial;
    height: initial;
  }

  @media (width > 576px) {
    display: flex;
    position: initial;
    right: initial;
    bottom: initial;
    clip-path: initial;
    background-color: transparent;
    width: initial;
    height: initial;
  }
}

.clip-wrapper[data-open="true"] {
  animation: fade-in 500ms forwards;

  @media (width < 576px) and (prefers-reduced-motion) {
    display: flex;
    position: fixed;
    right: 0;
    bottom: 0;
    animation: none;
    background-color: rgba(255, 255, 255, 0.9);
    width: 100%;
    height: 100%;
  }

  @media (width > 576px) {
    animation: none;
  }
}

.clip-wrapper[data-closing="true"] {
  animation: fade-out 500ms forwards;

  @media (width < 576px) and (prefers-reduced-motion) {
    animation: none;
  }

  @media (width > 576px) {
    animation: none;
  }
}

@keyframes fade-in {
  0% {
    clip-path: circle(21px at calc(100% - 58px) calc(100% - 38px));
  }
  100% {
    clip-path: circle(80%);
  }
}

@keyframes fade-out {
  0% {
    clip-path: circle(80%);
  }
  100% {
    clip-path: circle(21px at calc(100% - 58px) calc(100% - 38px));
  }
}
